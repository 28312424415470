import React, { useState } from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-primary-500 rounded-lg relative`
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const SecondaryLink = tw(Link)`text-gray-100 border-gray-500 hover:bg-gray-100 hover:text-primary-500 hover:border-primary-500`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`

const ContactFormModal = tw.div`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`;
const FormContainer = tw.div`relative bg-white rounded-lg p-8 max-w-md w-full mx-4`;
const FormTitle = tw.h3`text-2xl font-bold mb-4 text-gray-900`;
const Input = tw.input`w-full p-2 mb-4 border rounded`;
const TextArea = tw.textarea`w-full p-2 mb-4 border rounded h-32`;
const FormButton = tw.button`bg-primary-500 text-white px-6 py-2 rounded hover:bg-primary-600`;
const CloseButton = tw.button`absolute top-0 right-0 p-4 text-gray-600 hover:text-gray-800`;

export default ({
  text = "Plusieurs sociétés ont déja adopté Tanga. Qu'attendez-vous? ",
  primaryLinkText = "Commencer",
  primaryLinkUrl = "https://sandbox.tangagroup.africa",
  secondaryLinkText = "Contactez-Nous",
  pushDownFooter = true
}) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://staging-api.tangagroup.africa/api/v1/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        alert('Message envoyé avec succès!');
        setShowModal(false);
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert('Echec d\'envoie de message. Veillez réessayer.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Une erreur s\'est produite, Veillez réessayer plus tard.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Text>{text}</Text>
            </TextContainer>
            <LinksContainer>
              <PrimaryLink href={primaryLinkUrl} target="blank_">{primaryLinkText}</PrimaryLink>
              <SecondaryLink onClick={() => setShowModal(true)}>{secondaryLinkText}</SecondaryLink>
            </LinksContainer>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>

      {showModal && (
        <ContactFormModal>
          <FormContainer>
            <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
            <FormTitle>Contactez-nous</FormTitle>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="name"
                placeholder="Votre nom"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <Input
                type="email"
                name="email"
                placeholder="Votre email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <TextArea
                name="message"
                placeholder="Votre message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              <FormButton type="submit">Envoyer</FormButton>
            </form>
          </FormContainer>
        </ContactFormModal>
      )}
    </Container>
  );
};
